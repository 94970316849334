import { darken, rgba } from 'polished';
import { css } from 'styled-components';

import { type ButtonWrapperProps } from '../../components/design-system/Button';

import { type ThemeDefinition } from './definition';
import { bounceHorizontally, SHARED_THEME_STYLES, spin } from './sharedStyles';

export type Theme = ThemeDefinition;
export { SHARED_THEME_STYLES, spin, bounceHorizontally };

export const ColorVars = SHARED_THEME_STYLES.colors;

export const DARK_THEME: Theme = {
  ...SHARED_THEME_STYLES,
  backgroundColor: ColorVars.OBSIDIAN,
  borderColor: {
    MUTED: rgba(ColorVars.LIGHT_PINK, 0.15),
    DEFAULT: rgba(ColorVars.LIGHT_PINK, 0.3),
    LOUD: ColorVars.LIGHT_PINK
  },
  buttonLoudStyles: css<ButtonWrapperProps>`
    &::after {
      background: linear-gradient(
        45deg,
        ${({ theme }) => rgba(theme.highlightColor, 0.85)},
        ${({ theme }) => rgba(theme.colors.LIGHT_PURPLE, 0.5)}
      );
      opacity: ${({ $active, $stylez }) =>
        $active || $stylez === 'loud' ? '0.2' : '0'};
    }
  `,
  customerLogoColor: css`
    // Color translation to WHITE
    filter: brightness(0) invert(1);
  `,
  fontColor: {
    DEFAULT: ColorVars.LIGHT_PINK,
    MUTED: rgba(ColorVars.LIGHT_PINK, 0.6),
    LOUD: ColorVars.WHITE
  },
  gradientFade: css`linear-gradient(183.89deg,rgba(245,192,192,.02) 3.22%,rgba(0,0,0,.2) 240.48%),rgba(245,192,192,.02);`,
  gradientText: {
    DEFAULT: css`
      background-image: linear-gradient(
        55deg,
        ${({ theme }) => theme.colors.WHITE} 20%,
        ${({ theme }) => rgba(theme.colors.LIGHT_PINK, 0.8)} 100%
      );
    `,
    LOUD: css`
      background-image: linear-gradient(
        55deg,
        ${({ theme }) => theme.colors.LIGHT_PINK} 20%,
        ${({ theme }) => darken(0.05, theme.colors.WHITE)} 50%,
        ${({ theme }) => theme.colors.LIGHT_PURPLE} 80%
      );
    `,
    TWOSTOP: css`
      background-image: linear-gradient(
        93deg,
        ${({ theme }) => theme.colors.WHITE} 10.09%,
        ${({ theme }) => theme.colors.LIGHT_PINK} 159%
      );
    `
  },
  highlightColor: ColorVars.LIGHT_PINK,
  iconColor: {
    DEFAULT: ColorVars.LIGHT_PINK,
    MUTED: ColorVars.LIGHT_PURPLE
  },
  nav: {
    sticky: {
      backgroundColor: rgba(ColorVars.OBSIDIAN, 0.9)
    },
    dropdown: {
      backgroundColor: rgba(ColorVars.OBSIDIAN, 0.99)
    }
  },
  footer: {
    divider: {
      color: rgba(ColorVars.LIGHT_PINK, 0.5)
    }
  }
};

export const useDarkTheme = (): Theme => {
  return DARK_THEME;
};

export const LIGHT_THEME: Theme = {
  ...SHARED_THEME_STYLES,
  backgroundColor: ColorVars.WHITE,
  borderColor: {
    MUTED: rgba(ColorVars.LIGHT_PURPLE, 0.2),
    DEFAULT: ColorVars.LIGHT_PURPLE,
    LOUD: ColorVars.PURPLE
  },
  buttonLoudStyles: css<ButtonWrapperProps>`
    color: ${({ theme }) => theme.colors.WHITE};

    svg {
      color: ${({ theme }) => theme.colors.WHITE};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.PURPLE};
    }

    &::after {
      background: linear-gradient(
        45deg,
        ${({ theme }) => rgba(theme.colors.PURPLE, 0.85)},
        ${({ theme }) => rgba(theme.colors.PURPLE, 1)}
      );
    }
  `,
  customerLogoColor: css`
    // Color translation to BLUE CHARCOAL
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(339deg)
      brightness(100%) contrast(101%);
  `,
  fontColor: {
    DEFAULT: ColorVars.PURPLE,
    MUTED: rgba(ColorVars.PURPLE, 0.6),
    LOUD: ColorVars.BLACK
  },
  gradientFade: css`linear-gradient(180deg, rgba(173, 142, 182, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);`,
  gradientText: {
    DEFAULT: css`
      background-image: linear-gradient(
        90deg,
        ${({ theme }) => theme.colors.PURPLE} 0%,
        ${({ theme }) => theme.colors.BLACK} 100%
      );
    `,
    LOUD: css`
      background-image: linear-gradient(
        55deg,
        ${({ theme }) => theme.colors.PURPLE} 20%,
        ${({ theme }) => darken(0.05, theme.colors.BLACK)} 50%,
        ${({ theme }) => theme.colors.PURPLE} 80%
      );
    `,
    TWOSTOP: css`
      background-image: linear-gradient(
        93deg,
        ${({ theme }) => theme.colors.PURPLE} 10.09%,
        ${({ theme }) => theme.colors.BLACK} 159%
      );
    `
  },
  highlightColor: ColorVars.PURPLE,
  iconColor: {
    DEFAULT: ColorVars.PURPLE,
    MUTED: ColorVars.PURPLE
  },
  nav: {
    sticky: {
      backgroundColor: rgba(ColorVars.LIGHT_PURPLE, 0.9)
    },
    dropdown: {
      backgroundColor: rgba(ColorVars.LIGHT_PURPLE, 0.99)
    }
  },
  footer: {
    divider: {
      color: rgba(ColorVars.PURPLE, 0.5)
    }
  }
};

export const useLightTheme = (): Theme => {
  return LIGHT_THEME;
};
